<template>
  <div @click="closeDrop($event)">
    <!-- <Header :showDeepPlan="showDeepPlan" /> -->
    <MenuFilter v-if="filter" :menu="menu" :bgColor="bgColor" :routeData="filteredData"></MenuFilter>

    <!--Header component-->
    <component :is="headerComponent" :playerInstance="playerInstance" :isAuth="isAuthOpen"></component>
    <!--router-view-->
    <router-view
      :class="[currentRoute === 'detail' ? 'disable-margin' : 'enable-margin', 'body-min-width']"
      :playerInstance="playerInstance"
      @openAuth="(value) => toggleAuthPopup(value)"
      v-if="!isMobileView && !isServerDown"
    />
    <!-- Server Down page -->
    <ServerDown v-if="isServerDown"></ServerDown>

    <!-- Mobile App down load page -->
    <MobileWeb v-if="isMobileView && !isServerDown"></MobileWeb>
    <!--Footer component-->
    <component
      :is="footerComponent"
      v-if="!isMobileView"
      :showDeepAbout="showDeepAbout"
      :showDeepHelp="showDeepHelp"
    ></component>

    <transition>
      <component
        :is="popupComponent"
        :formInfo="authInfo"
        :content="content"
        :menu="menu"
        :closePopup="() => closeThePopup()"
        :closePlayerEvent="() => (popupComponent = null)"
      ></component>
    </transition>
    <PlayerComponent
      v-show="isPlayerVisible"
      :content="content"
      :seriesData="seriesData"
      :playerInstance="playerInstance"
      :closePlayerEvent="handlePlayerCloseEvent"
    />
    <transition>
      <overlayLight v-if="showOverlay"></overlayLight>
    </transition>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import { vLivePlayer } from "/src/vue-player/vLiveWebPlayer.js";
import { store } from "@/store/store";

export default {
  name: "Init",
  data() {
    return {
      mobileBrowserController: null,
      isMobileView: false,
      filter: false,
      menu: null,
      isPlayerVisible: false,
      content: null,
      toggleAuth: false,
      authInfo: {},
      enableAuth: true,
      isOverlay: false,
      showDeepHelp: null,
      showDeepAbout: null,
      showDeepPlan: null,
      showOverlay: false,
      headerComponent: null,
      footerComponent: null,
      popupComponent: null,
      currentRoute: null,
      bgColor: null,
      filteredData: null,
      playerInstance: null,
      seriesData: null,
      isAuthOpen: false,

      availabilityList: [],
      page: 1,
      pageSize: 15,
      isServerDown: false
    };
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberid", "profileid"]),
  },
  watch: {
    $route(to, from) {
      //logging screen view google analytics event.
      let GA = { screen_name: to.name };
      this.pageViewEvent(GA);

      this.closePlayerPopup(to, from);

      // call methods based on routes.
      this.invokeMethodsBasedOnRoutes();

      //To check denied routes.
      // this.deniedRoutes();
    },

    subscriberid(val) {
      if (val) {
        console.log("THE WATCHER IS CALLED", val);
        this.fetchAvailability();
        this.loadHeaderFooter();
      }
    },

    profileid(val) {
      if(val) {
        this.loadHeaderFooter();
      }
    }
  },
  created() {
    let convertingMobileViewControllerToBoolean = JSON.parse(this.appConfig.mobileViewController.toLowerCase());
    this.mobileBrowserController = convertingMobileViewControllerToBoolean;

    // Load header and footer.
    this.loadHeaderFooter();

    if(this.appConfig && this.appConfig.featureEnabled.isServerDown) {
      this.isServerDown = true;
      return;
    }

    if (sessionStorage.getItem("isActiveDesktopView")) {
      sessionStorage.removeItem("isActiveDesktopView");
    } else {
      this.detectMobile();
    }
    //this.checkMobileWeb();
    this.renderAuth();
    this.branchDeepLinks();

    this.fetchAvailability();

    eventBus.$on("check-for-series-route", () => {
      this.fetchAvailability();
    });

    this.invokeMethodsBasedOnRoutes(); // calling methods based on routes.

    console.log("THE PLAYER INSTANCE FROM INIT ---->", this.playerInstance);

    //show category filter in detail page
    eventBus.$on("show-category-filter", (payload) => {
      console.log("entered---->");
      this.filter = payload.state;
      this.menu = payload.menu;
      this.bgColor = payload.bgColor;
      this.filteredData = payload.title;
    });

    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible) {
        document.querySelector("body").style.overflow = "hidden";
      } else {
        document.querySelector("body").style.overflow = "auto";
      }
      this.isPlayerVisible = isVisible;
    });

    eventBus.$on("triggerInitView", (payload) => {
      this.isMobileView = false;
      this.emitToSwitchHeader();
    });

    // eventBus.$on("load-player-content", obj => {
    //   this.content = obj.content;
    //   this.seriesData = obj.seriesContent;
    // });
    eventBus.$on("load-player-content", (response) => {
      this.content = response.content;

      if (response.seriesContent) {
        this.seriesData = response.seriesContent;
      }
      // this.seriesData = content;
    });

    eventBus.$on("vlive-player-ready", (event) => {
      // alert("THIS IS PLAYER INSTANCE");

      let config = {
        platform: "vliveweb",
        skipTime: 10,
        progressColor: "#FF9F0A",
        tokenApiURL: this.appConfig.providerDetails.drmTokenUrl,
        orientation: this.$i18n.locale === "ara" ? "rtl" : "ltr",
      };

      let playerSettings = {
        container: "vlive-player",
        showSettings: true,
        showCast: false,
        showPlaylist: true,
        hasWatermark: false,
        captionLang: "ar",
        audioLang: "tr",
        displayLang: this.$i18n.locale,
        fairplayCertificate: "/static/noorplayfairplay.cer",
        playerConfig: config,
      };

      if (this.$i18n.locale == "ara") {
        playerSettings.localeStrings = {
          qualityTitle: "الجودة",
          audioTitle: "الصوت",
          subtitleTitle: "ترجمة",
          offText: "إيقاف",
          autoText: "تلقائي",
          skipIntro: "تخطى المقدمة‎",
          nextEpisode: "الحلقة التالية‎",
          ar: "العربية",
          tr: "التركية",
          ru: "الروسية",
          hi: "الهندية",
          en: "الإنجليزية",
          ko: "الكورية",
          zh: "صينى",
          uz: "الأوزبكية",
          kk: "الكازاخستانية"
        };
      } else {
        playerSettings.localeStrings = {
          qualityTitle: "QUALITY",
          audioTitle: "AUDIO",
          subtitleTitle: "SUBTITLES",
          offText: "Off",
          autoText: "Auto",
          skipIntro: "SKIP INTRO",
          nextEpisode: "NEXT EPISODE",
        };
      }

      // if (this.$i18n.locale == "ara") {
      //   playerSettings.localeStrings = {
      //     qualityTitle: "الجودة",
      //     audioTitle: "الصوت",
      //     subtitleTitle: "ترجمة",
      //     offText: "إيقاف",
      //     autoText: "تلقاءي",
      //     ar: "العربية",
      //     tr: "التركية",
      //     ru: "الروسية",
      //     hi: "الهندية",
      //     en: "الإنجليزية",
      //   };
      // }

      this.playerInstance = new vLivePlayer(playerSettings, []);
    });

    eventBus.$on("check-local-storage", (localeLang) => {
      let playerSettings = {};
      let config = {
        platform: "vliveweb",
        skipTime: 10,
        progressColor: "#FF9F0A",
        tokenApiURL: this.appConfig.providerDetails.drmTokenUrl,
        orientation: this.$i18n.locale === "ara" ? "rtl" : "ltr",
      };

      playerSettings.playerConfig = config;

      // if (this.$i18n.locale == "ara") {
      //   playerSettings.localeStrings = {
      //     qualityTitle: "الجودة",
      //     audioTitle: "الصوت",
      //     subtitleTitle: "ترجمة",
      //     offText: "إيقاف",
      //     autoText: "تلقاءي",
      //     ar: "العربية",
      //     tr: "التركية",
      //     ru: "الروسية",
      //     hi: "الهندية",
      //     en: "الإنجليزية",
      //   };
      // } else {
      //   playerSettings.localeStrings = {
      //     qualityTitle: "QUALITY",
      //     audioTitle: "AUDIO",
      //     subtitleTitle: "SUBTITLES",
      //     offText: "Off",
      //     autoText: "Auto",
      //   };
      // }

      playerSettings.displayLang = this.$i18n.locale;

      if (this.$i18n.locale == "ara") {
        playerSettings.localeStrings = {
          qualityTitle: "الجودة",
          audioTitle: "الصوت",
          subtitleTitle: "ترجمة",
          offText: "إيقاف",
          autoText: "تلقائي",
          skipIntro: "تخطى المقدمة‎",
          nextEpisode: "الحلقة التالية‎",
          ar: "العربية",
          tr: "التركية",
          ru: "الروسية",
          hi: "الهندية",
          en: "الإنجليزية",
          ko: "الكورية",
          zh: "صينى",
          uz: "الأوزبكية",
          kk: "الكازاخستانية"
        };
      } else {
        playerSettings.localeStrings = {
          qualityTitle: "QUALITY",
          audioTitle: "AUDIO",
          subtitleTitle: "SUBTITLES",
          offText: "Off",
          autoText: "Auto",
          skipIntro: "SKIP INTRO",
          nextEpisode: "NEXT EPISODE",
        };
      }

      this.playerInstance.settings = playerSettings;
    });
  },
  mounted() {
    //To check denied routes.
    // this.deniedRoutes();
    console.log("what is the current route============ ", this.$route.name);
    eventBus.$on("toggleMenuFilter", (response) => {
      // this.filter = response.state;
      if (response.state) {
        this.menu = response.menu;
        // this.filter = true;
        this.popupComponent = () =>
          import(/* webpackChunkName: "MenuFilter" */ "@/components/Templates/categoryFilter.vue");
      }
    });
    // eventBus.$on("togglePlayer", response => {
    //   // this.togglePlayer(response.state);
    //   if (response.state) {
    //     this.content = response.content;
    //     this.popupComponent = () =>
    //       import(
    //         /* webpackChunkName: "Player" */ "@/components/Popups/player.vue"
    //       );
    //   }
    // });

    eventBus.$on("authPopup", (response) => {
      // this.toggleAuthPopup(response.state);

      if (response.state) {
        this.authInfo = { ...response };
        this.authInfo.formType = response.formType;
        this.authInfo.previousAction = response.previousAction;
        this.popupComponent = () =>
          import(/* webpackChunkName: "auth" */ "@/components/Popups/Authentication/userAuth.vue");
      }
    });

    eventBus.$on("enableOverlayLoader", (state) => {
      // this.isOverlay = state;
      if (state) {
        this.popupComponent = () =>
          import(/* webpackChunkName: "overlay" */ "@/components/Templates/overlayLoader.vue");
      } else {
        this.popupComponent = null;
      }
    });

    eventBus.$on("show-light-overlay", (state) => {
      this.showOverlay = state;
    });

    this.registerErrorEventForDeviceToken();
  },
  beforeDestroy() {
    eventBus.$off("check-for-series-route");
    eventBus.$off("show-light-overlay");
  },
  methods: {
    emitToSwitchHeader() {
      eventBus.$emit("switchHeaderBack");
    },
    closeThePopup() {
      console.log("THIS IS IT NEWWWW111");
      this.popupComponent = null;

      if (!this.subscriberid) {
        eventBus.$emit("close-plan-popup", true);
      }
    },
    
    detectMobile() {
      let isMobile = "";
				let querycampaign = false
				if ( this.$route.query.couponcode ) {
						querycampaign = true;
				}
      if (
        this.mobileBrowserController &&
        this.$route.name !== "Profile" &&
        this.$route.name !== "partner-switching" &&
        this.$route.name !== "telcoPaymentHandler" && 
        this.$route.name !== "voucher" && 
        this.$route.name !== "contentPlayDeeplink" &&
        this.$route.name !== "seeAllPlans" && 
        this.$route.name !== "planandcoupondeeplink" &&
        this.$route.name !== "termsofuse" &&
        this.$route.name !== "privacyPolicy" &&
        this.$route.name !== "termsofuse-ar" &&
        this.$route.name !== "privacyPolicy-ar" &&
				this.$route.name !== "switchProfile" &&
				querycampaign == false &&
        this.$route.name !== "loginWithPinView" 
      ) {
        isMobile = RegExp(/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);

        if (!isMobile) {
          const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);

          if (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
            isMobile = true;
          }
        }
        if (isMobile) {
          this.isMobileView = true;
          localStorage.setItem("isMobile", true);
        } else {
          console.log("it is a web browser=============================================");
          this.isMobileView = false;
        }
      }
			if (this.$route.path.includes('/movie') || this.$route.path.includes('/series')) {
				// The current route includes 'return'
				this.isMobileView = false;
			}
      console.log("is mobile from detectMobile=============================", isMobile);
    },
    // checkMobileWeb() {
    //    console.log("check mobile web============", this.isMobileView);
    //   if (this.mobileBrowserController && this.$route.name !== "Profile") {
    //     if (
    //       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //         navigator.userAgent
    //       ) ||
    //       screen.width <= 1100
    //     ) {
    //       this.isMobileView = true;
    //     } else {
    //       this.isMobileView = false;
    //     }
    //   } else {
    //     this.isActiveComponent = () => import("./components/Init/Init.vue");
    //   }
    // },
    closePlayerPopup(to, from) {
      if (from !== to && this.isPlayerVisible) {
        this.isPlayerVisible = false;
      }
    },

    registerErrorEventForDeviceToken() {
      eventBus.$on("deviceRegister_error", (response) => {
        if (this.$route.name === "partner-switching") {
          let data = {
            partner_name: this.$route.params && this.$route.params.id ? this.$route.params.id : "",
            error_type: "Device_Register",
            error_code: response.errorcode ? response.errorcode : response,
            error_message: response.reason ? response.reason : response,
          };
          this.telco_error_event(data);
        }
      });
    },
    toggleAuthPopup(value) {
      console.log("toggled auth popup", value);
      this.isAuthOpen = value;
    },
    loadHeaderFooter() {
      (this.headerComponent = () => import(/* webpackChunkName: "header" */ "@/components/Header/Header.vue")),
        // (this.footerComponent = () =>
        //   import(
        //     /* webpackChunkName: "footer" */ "@/components/Footer/Footer.vue"
        //   ));
        (this.footerComponent = () =>
          import(/* webpackChunkName: "footer" */ "@/components/Footer/noorplay_Footer.vue"));
    },
    handlePlayerCloseEvent() {
      console.log("player closed from init");
      eventBus.$emit("player-closed");
      // if (this.playerInstance != null) {
      //   console.log("player instance", this.playerInstance);
      //   this.playerInstance.unloadPlayer();
      // }
      this.isPlayerVisible = false;

      document.querySelector("body").style.overflow = "auto";

      this.content = null;
    },
    deniedRoutes() {
      this.currentRoute = this.$router.currentRoute.name;
      if (this.currentRoute === "detail") {
        this.headerComponent = null;
        this.footerComponent = null;
      } else {
        this.loadHeaderFooter();
      }
    },
    renderAuth() {
      let currentRoute = this.$router.currentRoute;
      if (currentRoute && currentRoute.params) {
        if (currentRoute.params.link === "content") {
          this.enableAuth = false;
        } else {
          this.enableAuth = true;
        }
      } else {
        this.enableAuth = true;
      }
    },
    closeDrop(event) {
      if (event.target.id !== "searchCat" && event.target.id !== "searchName" && event.target.id !== "searchArrow") {
        eventBus.$emit("toggle-SearchCat", false);
      }
      if (
        event.target.id !== "searchInput" &&
        event.target.id !== "searchIcon" &&
        event.target.id !== "search" &&
        event.target.id !== "suggestion-items" &&
        event.target.id !== "search-history" &&
        event.target.id !== "history-item" &&
        event.target.id !== "del-history" &&
        event.target.id !== "play-imgs" &&
        event.target.id !== "play-img-item" &&
        event.target.id !== "searched-container" &&
        event.target.id !== "searched-container-box" &&
        event.target.id !== "searched-container-para"
      ) {
        this.showOverlay = false;
        // eventBus.$emit("show-light-overlay", false);
        eventBus.$emit("toggle-SearchInput", false);
      }
      if (event.target.id !== "hamburger-icon") {
        eventBus.$emit("toggle-MenuDrop", false);
      }
      if (
        event.target.id !== "noShowProfile" &&
        event.target.id !== "profile-detail" &&
        event.target.id !== "profile" &&
        event.target.id !== "arrow"
      ) {
        eventBus.$emit("toggle-profile-drop", false);
      }
      if (event.target.id !== "small-genre") {
        eventBus.$emit("toggle-small-genre", false);
      }
      if (event.target.id !== "small-language") {
        eventBus.$emit("toggle-small-language", false);
      }
      if (event.target.id !== "small-time") {
        eventBus.$emit("toggle-small-time", false);
      }
      if (
        event.target.id !== "tab-Related" &&
        event.target.id !== "tab-Trailers" &&
        event.target.id !== "tab-Items" &&
        event.target.id !== "rel-contain" &&
        event.target.id !== "rel-contain-title"
      ) {
        eventBus.$emit("close-related-tab", false);
      }

      if (
        event.target.id !== "notification-drop-parent" &&
        event.target.id !== "noShowNotify" &&
        event.target.id !== "notify-drop"
      ) {
        eventBus.$emit("close-notification-drop", false);
      }
    },
    branchDeepLinks() {
      if (this.$router.currentRoute.path === "/lookup") {
        if (!this.subscriberid) {
          this.toggleAuth = true;
          this.authInfo.formType = "lookup";
        }
      } else if (this.$router.currentRoute.path === "/help") {
        this.showDeepHelp = true;
      } else if (this.$router.currentRoute.path === "/about") {
        this.showDeepAbout = true;
      } else if (this.$router.currentRoute.path === "/menu/watchlist") {
        if (!this.subscriberid) {
          this.$router.push({ name: "Home" });
        }
      } else if (this.$router.currentRoute.path === "/menu/plan") {
        if (!this.subscriberid) {
          this.$router.push({ name: "Home" });
        } else {
          let payload = {
            listPlans: "ALL",
          };
          this.showDeepPlan = true;
        }
      } else if (this.$router.currentRoute.path === "/menu/bold") {
        if (localStorage.getItem("subscriberDetails")) {
          let details = JSON.parse(localStorage.getItem("subscriberDetails"));
          if (details.age < 18) {
            this.$router.push({ name: "Home" });
          } else {
            let menuDetail = this.appConfig.menu[2];
            console.log("menuDetail", menuDetail);
            this.$router.push({ name: "Bold", params: { menuDetail } });
          }
        }
      }
    },
    invokeMethodsBasedOnRoutes() {
      let currentRoute = this.$router.currentRoute.name;
      if (currentRoute !== "partner-switching") {
        console.log("The INVOKE METHOD IS CALLED");
        //this.checkForMovieResponse();
        // this.fetchAvailability();
      }
    },
    checkForMovieResponse() {
      let appconfig = JSON.parse(localStorage.getItem("appConfig"));
      let moviesSection = appconfig.screens.filter((el) => {
        return el.id === "MOVIES";
      });
      let screen = moviesSection[0].sections[0];

      let payload = {
        endpoint: screen.endpoint,
        params: screen.parameters,
      };

      store
        .dispatch("contentList", payload)
        .then((response) => {
          if (response.data.errorcode === 7109) {
            store.commit("setIsMovieEnabled", "No");
          } else {
            store.commit("setIsMovieEnabled", "Yes");
          }
        })
        .catch((error) => console.log(error));
    },
    fetchAvailability() {
      this.page = 1;

      this.availabilityList = [];

      let payload = {
        page: this.page,
        pagesize: this.pageSize,
      };

      this.fetchAvailabilityFunc(payload);
    },

    fetchAvailabilityFunc(payload) {
      store
        .dispatch("availabilityList", payload)
        .then((response) => {
          //storing total count in local variable.
          let totalCount = response.data.totalcount;
          //appeding availability response for the exising array.
          this.availabilityList = [...this.availabilityList, ...response.data.data];
          //commit the updated availability list.
          store.commit("setAvailabilityList", this.availabilityList);

          console.log("FETCH AVAILABLITY", this.availabilityList);

          //checking if the lenght of availabilties less than total count. If true call availability list agian.
          if (this.availabilityList.length < totalCount) {
            this.page++;

            const newPayload = {
              page: this.page,
              pagesize: this.pageSize,
            };

            this.fetchAvailabilityFunc(newPayload);
          }

          console.log("Final Availablity list", this.availabilityList);
        })
        .catch((error) => console.log(error));
    },
  },
  components: {
    MobileWeb: () => import("@/components/MobileWeb/mobileWeb.vue"),
    ServerDown: () => import("@/components/ServerDown/ServerDown.vue"),
    MenuFilter: () => import(/* webpackChunkName: "MenuFilter" */ "@/components/Templates/categoryFilter.vue"),
    overlayLight: () => import(/* webpackChunkName: "overlayLight" */ "@/components/Overlay/Overlay.vue"),
    PlayerComponent: () => import(/* webpackChunkName: "Player" */ "@/components/Popups/player.vue"),
  },
  mixins: [googleAnalytics],
};
</script>

<style scoped>
.disable-margin {
  margin-top: 0px;
}
.enable-margin {
  margin-top: 55px;
}
.body-min-width {
  min-height: 70vh;
}
</style>
